@keyframes breathing-keyframes {
    0% {
      transform: scale(0.9);
      opacity: 0.1;
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }

@media (prefers-reduced-motion: no-preference) {
    .breathing-animation {
        animation: breathing-keyframes ease-in 0.5s 1;
    }
}