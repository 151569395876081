.App {
  text-align: center;
  min-width: 300px;
  background-color: black;
  padding-bottom: 50px;
  line-height: 1.0;
  font-size: calc(22px);
  max-width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  gap: 3vmin;
  font-size: calc(3vmin);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bigger-textbox {
  text-align: left;
  max-width: 700px;
}

.header-logo {
  height: 14vmin;
}

.intro-picture {
  width: 85%;
}

.round-picture {
  width: 100%;
  border-radius: 50%;
  max-width: 350px;
}

.rectangular-picture {
  height: 300px;
  width: 300px;
}

.components-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1400px;
  width: 95%;
}

.stacked-components-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1400px;
  width: 95%;
}

.stairs-components-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (min-width: 700px) {
  .stairs-components-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1400px;
    width: 95%;
    min-height: 500px;
  }

  .intro-picture {
    width: 600px;
  }
}


.left-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: left;
  max-width: 1400px;
  width: 95%;
  padding-top: 50px;
}

.center-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  max-width: 1400px;
  padding-top: 50px;  
}

.link {
  color: #00c6ff;
}

.normal-text {
  color: white;
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 0.8em;
}

.main-heading{
  font-size: 3em;
  color: #00c6ff;
  font-weight: bold;
  font-family: 'Jaturat';
  text-transform: uppercase;  
}

.bigger-text{
  font-size: 3em;
  color: #00c6ff;
  font-weight: bold;
  font-family: 'Jaturat';
  text-transform: uppercase;  
}

.small-headline {
    font-size: 1.3em;
    color: #00c6ff;
    font-style: italic;
    font-weight: bold;
    font-family: 'Jaturat';
    text-transform: uppercase; 
}

.highlighted-text {
  color: black;
  background-color: #00c6ff;
}

.medium-headline {
  font-size: 1.7em;
  font-weight: bold;
  font-family: 'Jaturat';
  text-transform: uppercase;
}

.large-headline {
  font-size: 1.9em;
  color: #00c6ff;
  font-weight: bold;
  font-family: 'Jaturat';
  text-transform: uppercase;
}

.picture-textbox {
  max-width: 400px;
  text-align: left;
}

.picture-stamp {
  max-width: 400px;
  text-align: center;
}

.customerbox {
  max-width: 400px;
  text-align: left;
  position: relative;
}

.dots-frame {
  max-width: 1400px;
  position: relative;
}

.bazmek {
  width: 100%;
  z-index: 1;
}

.portait-picture {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  z-index: 2;
  border-radius: 50%;
}

.customer-name {
  color: black;
  position: absolute;
  z-index: 3;
  right: 20%;
  bottom: 20%;
  font-size: 1.43rem;
  width: 20%;
  text-align: left;
  font-weight: bold;
}

.customer-text {
  color: black;
  position: absolute;
  z-index: 4;
  font-size: 1.13rem;
  line-height: 1.5;
  width: 65%;
  left: 8%;
  top: 2%;
}

.frame-text {
  color: white;
  position: absolute;
  font-style: italic;
  z-index: 2;
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
  width: 80%;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%); 
}

.dots-image {
  z-index: 1;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.dots-frame {
  max-width: 1400px;
  position: relative;
}

.smaller-textbox {
  max-width: 300px;
  text-align: right;
}

.dots3 {
  height: 1em;
}

.table-column {
  max-width: 1400px;
  min-width: 50%;
  width: 95%;
}
@media only screen and (min-width: 500px) {
  .table-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}


.table-values-text {
  color: white;
  font-family: sans-serif;
  font-size: 1.3em;
}