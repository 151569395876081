@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  
  @mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex:  $values;
    -webkit-flex:  $values;
    -ms-flex:  $values;
    flex:  $values;
  }
  
  @mixin order($val) {
    -webkit-box-ordinal-group: $val;  
    -moz-box-ordinal-group: $val;     
    -ms-flex-order: $val;     
    -webkit-order: $val;  
    order: $val;
  }
  
  .wrapper {
    @include flexbox();
  }
  
  .item {
    @include flex(1 200px);
    @include order(2);
  }