@font-face{
  font-family: "Jaturat";
  src: url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.eot");
  src: url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.eot?#iefix")format("embedded-opentype"),
      url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.woff")format("woff"),
      url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.woff2")format("woff2"),
      url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.ttf")format("truetype"),
      url("./Jaturat/cf3fb387331afda8f5d7f9c8dea3cd13.svg#Jaturat")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Jaturat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
